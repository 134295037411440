import React, { useState, useCallback } from 'react'
import { DialogTitle, DialogContent, DialogActions, Box, Typography } from '@material-ui/core'
import { ThemeButton } from '../../../../ui'
import { DateInput } from '../../../../ui'
import { SelectInput } from '../../../../ui'
import { useCombos } from '../../../combos'
import { SIR_STATES } from '../../constants'
import { FILE_FIELD_NAMES, FILE_FIELD_IDS } from '../form/sir-form.constants'

export const PendingDatesDialog = ({
  title = 'Confirmar fechas programadas',
  text_cierre = 'Día de cierre',
  text_apertura = 'Día de apertura',
  yesText = 'Confirmar',
  yesColor = 'primary',
  yesAction = () => {},
  yesAndClose = true,
  noText = 'Cancelar',
  noColor = 'default',
  noAction = () => {},
  noAndClose = true,
  close,
  dia_cierre,
  dia_apertura,
  hora_cierre,
  hora_apertura,
  application,
  updateApplication,
  generateBudget,
}) => {
  const [formState, setFormState] = useState({
    ...application,
    presupuesto_sir: {
      ...application.presupuesto_sir,
      dia_cierre: dia_cierre,
      hora_cierre: hora_cierre,
      dia_apertura: dia_apertura,
      hora_apertura: hora_apertura,
      fecha_programacion_confirmada: true,
    },
  })
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')
  const combos = useCombos(['horarios_apertura'])

  let mateixDiaiHora = formState.presupuesto_sir.dia_apertura === null ||
    formState.presupuesto_sir.hora_apertura === "" ||
    formState.presupuesto_sir.dia_cierre === null ||
    formState.presupuesto_sir.hora_cierre === "" ||
    ((
        formState.presupuesto_sir.dia_cierre.slice(0, 10) ===
        formState.presupuesto_sir.dia_apertura.slice(0, 10) &&
        /* formState.presupuesto_sir.hora_cierre === formState.presupuesto_sir.hora_apertura && */
        combos.horarios_apertura.data.findIndex(
          ({ key }) => key === formState.presupuesto_sir.hora_cierre
        ) >=
        combos.horarios_apertura.data.findIndex(
          ({ key }) => key === formState.presupuesto_sir.hora_apertura
        )) ||
      formState.presupuesto_sir.dia_cierre.slice(0, 10) >
      formState.presupuesto_sir.dia_apertura.slice(0, 10))

  const handleChangeForm = useCallback(
    (e) => {
      const { name, value } = e.target
      setFormState((formState) => ({
        ...formState,
        presupuesto_sir: { ...formState.presupuesto_sir, [name]: value },
      }))
    },
    [setFormState]
  )

  const enviar = () => {
    setErrorMssg('')
    const budgetId = formState.presupuesto_sir.id
    let payload = {}
    let newPressure = null
    for (let key in formState.presupuesto_sir) {
      if (
        !FILE_FIELD_NAMES.some((fileName) => fileName === key) &&
        !FILE_FIELD_IDS.some((fileName) => fileName === key) &&
        (formState.presupuesto_sir[key] || formState.presupuesto_sir[key] === false)
      ) {
        if (key === 'num_viviendas') {
          payload[key] = parseInt(formState.presupuesto_sir[key])
        } else if (key === 'precio' || key === 'precio_con_iva') {
          if (formState.presupuesto_sir[key] !== application.presupuesto_sir[key])
            payload[key] = formState.presupuesto_sir[key].toString()
        } else if (key === 'aplica_apertura_cierre') {
          payload[key] = formState.presupuesto_sir[key] === '01' ? true : false
        } else if (key === 'sstt_id') {
          payload[key] = parseInt(formState.presupuesto_sir[key])
        } else payload[key] = formState.presupuesto_sir[key]
      }
      delete formState['rango_presion']
    }
    if (application.rango_presion !== formState.presupuesto_sir.rango_presion) {
      newPressure = formState.presupuesto_sir.rango_presion
    }
    setLoading(true)
    updateApplication(budgetId, application.id, payload, newPressure).then(() => {
      if (application['estado'] === SIR_STATES.BUDGET_CREATED.KEY) {
        generateBudget(application.id).then(() => {
          setErrorMssg('')
          setLoading(false)
        })
      } else {
        setErrorMssg('')
        setLoading(false)
      }
    })
  }
  return (
    <form>
      <Box p={4}>
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography variant="h4" color="primary">
              {title}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="row">
            <DateInput
              style={{ width: '80%' }}
              name="dia_cierre"
              title={text_cierre}
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              /* label="Dia de cierre" */
              value={formState.presupuesto_sir.dia_cierre || dia_cierre}
              /* maxDate={endDate ? endDate : null} */
              onChange={handleChangeForm}
            />

            <SelectInput
              name="hora_cierre"
              fullWidth={false}
              required={true}
              title="Horario de cierre"
              value={formState.presupuesto_sir.hora_cierre || hora_cierre}
              values={combos['horarios_apertura'].data}
              onChange={handleChangeForm}
            />
          </Box>
          <Box display="flex" flexDirection="row">
            <DateInput
              style={{ width: '80%' }}
              name="dia_apertura"
              title={text_apertura}
              disableToolbar
              variant="inline"
              format="dd/MM/yyyy"
              /*  label="Dia de apertura" */
              value={formState.presupuesto_sir.dia_apertura || dia_apertura}
              /* maxDate={endDate ? endDate : null} */
              onChange={handleChangeForm}
            />

            <SelectInput
              name="hora_apertura"
              fullWidth={false}
              required={true}
              title="Horario de apertura"
              placeholder="horas"
              value={formState.presupuesto_sir.hora_apertura || hora_apertura}
              values={combos['horarios_apertura'].data}
              onChange={handleChangeForm}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <ThemeButton
            labelM="small"
            color={noColor}
            onClick={() => {
              noAction()
              if (noAndClose) {
                close()
              }
            }}
          >
            {noText}
          </ThemeButton>
          <ThemeButton
            disabled={mateixDiaiHora}
            labelM="small"
            color={yesColor}
            onClick={() => {
              yesAction()
              if (yesAndClose) {
                enviar()
                close()
              }
            }}
          >
            {yesText}
          </ThemeButton>
        </DialogActions>
        {mateixDiaiHora ? (
          <p style={{ color: 'red' }}>
            El horario de cierre no puede ser igual o posterior al horario de apertura.
          </p>
        ) : null}
      </Box>
    </form>
  )
}
